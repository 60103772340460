// https://stackoverflow.com/a/950146
// https://lowrey.me/load-external-javascript-with-es6-promises/

export default (url, text = '') => {
  return new Promise((resolve, reject) => {
    const head = document.head;
    const scripts = Array.prototype.slice.call(document.scripts);
    let hasScript = false;

    // prevent loading existing script
    scripts.forEach(script => {
      if (script.src === url) hasScript = true;
    });

    if (hasScript) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      if (text !== '') script.textContent = text;

      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);

      head.appendChild(script);
    }
  });
};
