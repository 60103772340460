import inView from '../2-helpers/in-view';
import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const milestones = $$('.js-report-milestone');
  if (!milestones) return;

  let count = 0;

  inView(
    milestones,
    (el, visible) => {
      if (visible) {
        if (count === 0) {
          el.classList.add('c-milestone--play');
        } else {
          setTimeout(() => {
            el.classList.add('c-milestone--play');
          }, count * 100);
        }

        count++;

        // Wait 1 frame
        window.requestAnimationFrame(() => {
          count--;
        });
      }
    },
    {
      offset: 0.15
    }
  );
};
