import inView from '../2-helpers/in-view';
import { $, $$ } from '../2-helpers/query-selector';
import { CountUp } from '../3-vendors/count-up';

export default () => {
  const keyFigures = $$('.js-report-key-figure');
  if (!keyFigures) return;

  let count = 0;

  inView(
    keyFigures,
    (el, visible) => {
      if (visible) {
        const numberEl = $('.js-report-key-figure-number', el);
        const number = numberEl.dataset.number;
        if (!number) return;

        const decimals = countDecimals(number);

        const options = {
          decimalPlaces: decimals,
          separator: '',
          decimal: document.documentElement.lang === 'de' ? ',' : '.',
          duration: 2
        };

        const counter = new CountUp(numberEl, number, options);

        if (count === 0) {
          el.classList.add('c-key-figure--play');
          if (!counter.error) counter.start();
        } else {
          setTimeout(() => {
            el.classList.add('c-key-figure--play');
            if (!counter.error) counter.start();
          }, count * 200);
        }

        count++;

        // Wait 1 frame
        window.requestAnimationFrame(() => {
          count--;
        });
      }
    },
    {
      offset: 0.15
    }
  );
};

function countDecimals(value) {
  if (value % 1 != 0) return value.toString().split('.')[1].length;
  return 0;
}
