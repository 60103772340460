__Init__; // Replaced by Rollup

// Modules
import initLazyload from './1-modules/lazyload';
import initNav from './1-modules/nav';
import initAnims from './1-modules/animation';
import initHomeHero from './1-modules/home-hero';
import { $ } from './2-helpers/query-selector';

function init() {
  initLazyload();
  initNav();
  initAnims();
  initHomeHero();

  // async
  if ($('.js-map')) {
    import('./1-modules/map').then(module => module.default());
  }

  if ($('.js-article-search')) {
    import('./1-modules/article-search').then(module => module.default());
  }

  if ($('.js-citation')) {
    import('./1-modules/citation').then(module => module.default());
  }

  if ($('.js-form')) {
    import('./1-modules/form').then(module => module.default());
  }

  if ($('.js-social-links')) {
    import('./1-modules/social-links').then(module => module.default());
  }

  if ($('.js-report-nav-link')) {
    import('./1-modules/report-nav-link').then(module => module.default());
  }

  if ($('.js-report-intro')) {
    import('./1-modules/report-intro').then(module => module.default());
  }

  if ($('.js-report-slider')) {
    import('./1-modules/report-slider').then(module => module.default());
  }

  if ($('.js-report-key-figure')) {
    import('./1-modules/report-key-figure').then(module => module.default());
  }

  if ($('.js-report-milestone')) {
    import('./1-modules/report-milestone').then(module => module.default());
  }

  if ($('.js-report-solutions')) {
    import('./1-modules/report-solutions').then(module => module.default());
  }

  if ($('.js-report-text-toggle')) {
    import('./1-modules/report-text-toggle').then(module => module.default());
  }

  if ($('.js-slideshow')) {
    import('./1-modules/slideshow').then(module => module.default());
  }

  if ($('.js-home-anim')) {
    import('./1-modules/home-animation').then(module => module.default());
  }
  import('./1-modules/cookie-settings-button').then(m => m.default());
}

init();
