import { $, $$ } from '../2-helpers/query-selector.js';
import Glide, { Controls } from '../3-vendors/glide.js';

export default function init() {
  const sliders = $$('.js-report-slider');

  for (const slider of sliders) {
    // Init
    const glide = new Glide(slider, {
      type: 'slider',
      //rewind: true,
      gap: 16,
      animationDuration: 700,
      dragThreshold: false,
      swipeThreshold: false,
      animationTimingFunc: 'ease'
    });

    glide.mount({ Controls });
  }
}
