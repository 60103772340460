import Awesomplete from '../3-vendors/awesomplete';
import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const form = $('.js-article-search-form');
  const input = $('.js-article-search-input');
  const suggestions = $('.js-article-search-suggestions');

  new Awesomplete(input, {
    list: suggestions,
    minChars: 2,
    maxItems: 5
  });

  document.addEventListener('awesomplete-select', e => {
    input.value = e.text.value;
    form.submit();
  });
};
