import { $$ } from '../2-helpers/query-selector';

import inView from '../2-helpers/in-view';

async function renderAnimation(el) {
  lottie.loadAnimation({
    container: el,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: el.dataset.json
  });
}

export default () => {
  const el = $$('.js-home-anim');

  if (!el) {
    return;
  }

  inView(
    el,
    (el, visible) => {
      if (visible) {
        el.classList.add('u-anim--play');
        renderAnimation(el);
      }
    },
    {
      offset: 0.2
    }
  );
};
