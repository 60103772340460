import loadScript from '../2-helpers/load-script';
import { $, $$ } from '../2-helpers/query-selector';

function addMapboxCss() {
  const styleSheets = Array.prototype.slice.call(document.styleSheets);
  const head = document.head;
  const css = 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css';
  let hasStyle = false;

  styleSheets.forEach(styleSheet => {
    if (styleSheet.href === css) hasStyle = true;
  });

  if (!hasStyle) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = css;
    head.insertBefore(link, head.lastChild);
  }
}

async function initMap(mapEl) {
  addMapboxCss();

  await loadScript(
    'https://api.tiles.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.js'
  );

  // Get data
  const wrapper = $('.js-map-wrapper', mapEl);
  const lng = parseFloat(mapEl.dataset.lng);
  const lat = parseFloat(mapEl.dataset.lat);
  const zoom = parseFloat(mapEl.dataset.zoom);
  const pitch = parseFloat(mapEl.dataset.pitch);
  const style = mapEl.dataset.style;
  const token = mapEl.dataset.token;

  // Create Map
  mapboxgl.accessToken = token;

  const map = new mapboxgl.Map({
    container: wrapper,
    style: style,
    zoom: zoom,
    pitch: pitch,
    center: [lng, lat],
    scrollZoom: false,
    boxZoom: false,
    keyboard: false,
    attributionControl: false,
    logoPosition: 'bottom-left'
  });

  const control = new mapboxgl.NavigationControl({
    showCompass: false
  });

  map.addControl(control, 'bottom-right');

  // marker
  if (!mapEl.classList.contains('js-map-event')) {
    // contact map
    const marker = $('.js-map-marker', mapEl);

    new mapboxgl.Marker(marker)
      .setLngLat([lng, lat])
      .addTo(map)
      .setOffset([0, -26]); // offset = marker height * -0.5
  } else {
    // event map
    const marker1 = $('.js-map-marker-1', mapEl);
    const marker2 = $('.js-map-marker-2', mapEl);

    new mapboxgl.Marker(marker1)
      .setLngLat([13.356568, 52.481709])
      .addTo(map)
      .setOffset([0, -26]);

    new mapboxgl.Marker(marker2)
      .setLngLat([13.412948, 52.522799])
      .addTo(map)
      .setOffset([0, -26]);
  }
}

export default (force = false) => {
  const mapEls = $$('.js-map');

  for (const mapEl of mapEls) {
    if (!force && (!mapEl || mapEl.dataset.hidden)) continue;
    if (mapEl.hasActiveMap) continue;

    mapEl.hasActiveMap = true;

    // map is non-essential and loads a lot of js
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => initMap(mapEl));
    } else {
      initMap(mapEl);
    }
  }
};
