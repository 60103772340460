import inView from '../2-helpers/in-view';
import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const hl = $$('.js-report-intro-hl');
  if (!hl) return;

  inView(
    hl,
    (el, visible) => {
      if (visible) {
        el.classList.add('l-report-intro__hl--play');
      }
    },
    {
      offset: 0.3
    }
  );
};
