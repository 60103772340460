import { $$ } from '../2-helpers/query-selector';

function init() {
  const settingButtons = $$('.js-cookie-settings-button');

  if (!settingButtons) return;

  for (const button of settingButtons) {
    button.addEventListener('click', e => {
      e.preventDefault();
      Cookiebot.show();
    });
  }
}

export default init;
