import bezier from '../2-helpers/bezier';
import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const wrapper = $('.js-report-solutions');
  if (!wrapper) return;

  // Init Lottie
  const anims = $$('.js-report-solutions-anim');
  const lottieAnims = [];

  function initLottie() {
    return new Promise((resolve, reject) => {
      anims.forEach((el, i) => {
        const json = el.dataset.json;
        if (!json) return;

        const speed = parseFloat(el.dataset.speed);

        const anim = lottie.loadAnimation({
          container: el,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: json
        });

        anim.setSpeed(speed);

        lottieAnims[i] = anim;
      });

      resolve();
    });
  }

  initLottie();

  const items = $$('.js-report-solutions-item');
  const visibleClass = 'l-report-solutions__item--visible';
  const texts = $$('.js-report-solutions-text');
};
