import { $ } from '../2-helpers/query-selector';
import Flickity from 'flickity';

export default () => {
  const slideshow = $('.js-slideshow');

  const options = {
    lazyLoad: false,
    prevNextButtons: window.matchMedia(`(min-width: ${960 / 16}em)`).matches,
    pageDots: true,
    wrapAround: true,
    dragThreshold: 10,
    arrowShape: 'M65 95L30 50 65 5'
  };

  new Flickity(slideshow, options);
};
