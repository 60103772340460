import setFocus from '../2-helpers/set-focus';
import onResize from '../2-helpers/on-resize';
import { $, $$ } from '../2-helpers/query-selector';

function openCitation(citation, toggle, popover) {
  citation.classList.add('o-citation--active');
  toggle.setAttribute('aria-expanded', 'true');
  setFocus(popover);

  // Popover position
  const toggleRect = toggle.getBoundingClientRect();

  // Regular position at center
  let left = popover.offsetWidth / -2 + toggleRect.width / 2;

  // Detect overflow
  const toggleCenter = toggleRect.left + toggleRect.width / 2;
  const popoverWidth = popover.offsetWidth;
  const bodyWidth = document.body.offsetWidth; // window.innerHeight does not work b/c overflow

  const overflowRight = toggleCenter + popoverWidth / 2;
  const overflowLeft = toggleCenter - popoverWidth / 2;

  if (overflowRight >= bodyWidth) {
    const offset = overflowRight - bodyWidth + 5;
    popover.style.setProperty('--offset', offset + 'px');

    left = left - offset;
  } else if (overflowLeft < 0) {
    const offset = overflowLeft - 5;
    popover.style.setProperty('--offset', offset + 'px');

    left = left - offset;
  }

  popover.style.setProperty('bottom', '100%');
  popover.style.setProperty('left', left + 'px');
}

function closeCitation(citation, toggle, popover) {
  citation.classList.remove('o-citation--active');
  toggle.setAttribute('aria-expanded', 'false');
  setFocus(toggle);
}

function closeAllCitations(citations) {
  for (const citation of citations) {
    if (citation.classList.contains('o-citation--active')) {
      const toggle = $('.js-citation-toggle', citation);
      const popover = $('.js-citation-popover', citation);

      closeCitation(citation, toggle, popover);
    }
  }
}

export default () => {
  const citations = $$('.js-citation');

  for (const citation of citations) {
    const toggle = $('.js-citation-toggle', citation);
    const closeButton = $('.js-citation-close-button', citation);
    const popover = $('.js-citation-popover', citation);

    toggle.addEventListener('click', e => {
      e.stopPropagation();

      if (!citation.classList.contains('o-citation--active')) {
        openCitation(citation, toggle, popover);
      } else {
        closeCitation(citation, toggle, popover);
      }
    });

    closeButton.addEventListener('click', () => {
      closeCitation(citation, toggle, popover);
    });

    // Prevent click to close within popover
    popover.addEventListener('click', e => {
      e.stopPropagation();
    });
  }

  document.body.addEventListener('click', () => {
    closeAllCitations(citations);
  });

  onResize(
    () => {
      closeAllCitations(citations);
    },
    {
      horizontalOnly: true
    }
  );
};
