import setFocus from '../2-helpers/set-focus';
import scrollTo from '../2-helpers/scroll-to.js';
import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const links = $$('.js-report-nav-link');

  for (const link of links) {
    link.addEventListener('click', function(e) {
      e.preventDefault();
      const target = $(link.getAttribute('href'));
      if (!target) return;

      const offset =
        target.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top;

      const delay = Math.min(Math.max(offset - window.pageYOffset, 1000), 3000);

      scrollTo(offset, delay).then(() => {
        setFocus(target);
      });
    });
  }
};
