import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const toggles = $$('.js-report-text-toggle');
  if (!toggles) return;

  const textExpandedClass = 'l-report-year__article-text--expanded';
  const toggleCloseClass = 'o-link--toggle-close';

  for (const toggle of toggles) {
    const text = $('.js-report-text-section', toggle.parentNode);
    const toggleText = $('span:first-child', toggle);
    let textVisible = false;

    toggle.addEventListener('click', () => {
      if (!textVisible) {
        textVisible = true;

        toggle.setAttribute('aria-expanded', 'true');
        toggle.classList.add(toggleCloseClass);
        toggleText.textContent = toggle.dataset.close;

        text.classList.add(textExpandedClass);
      } else {
        textVisible = false;

        toggle.setAttribute('aria-expanded', 'false');
        toggle.classList.remove(toggleCloseClass);
        toggleText.textContent = toggle.dataset.open;

        text.classList.remove(textExpandedClass);
      }
    });
  }
};
