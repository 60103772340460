import setFocus from '../2-helpers/set-focus';
import scrollTo from '../2-helpers/scroll-to';
import { $, $$ } from '../2-helpers/query-selector';

function validateRequiredCheckboxes(form) {
  const fieldset = $('.js-form-required-checkboxes', form);
  if (!fieldset) return true;

  const checkboxes = $$(
    '.js-form-required-checkboxes input[type="checkbox"]',
    form
  );
  let checkedCount = 0;

  for (const checkbox of checkboxes) {
    if (checkbox.checked) checkedCount++;
  }

  return checkedCount > 0;
}

function handleRequiredCheckboxes(form) {
  const fieldset = $('.js-form-required-checkboxes', form);
  if (!fieldset) return;

  const alert = $('.js-form-required-checkboxes-alert', form);
  const checkboxes = $$(
    '.js-form-required-checkboxes input[type="checkbox"]',
    form
  );

  for (const checkbox of checkboxes) {
    checkbox.addEventListener('click', () => {
      const valid = validateRequiredCheckboxes(form);

      if (valid) {
        alert.classList.remove('o-fieldset__alert--visible');
        alert.removeAttribute('role', 'alert');
      } else {
        alert.classList.add('o-fieldset__alert--visible');
        alert.setAttribute('role', 'alert');
      }
    });
  }
}

function handleRadioToggles(form) {
  const toggle = $('.js-form-radio-toggle', form);
  if (!toggle) return;

  const toggleOn = $('.js-form-radio-toggle-on input[type="radio"]', form);
  const toggleOff = $('.js-form-radio-toggle-off input[type="radio"]', form);
  const toggleTarget = $('.js-form-radio-toggle-target', form);
  const targetRadios = $$('input[type="radio"]', toggleTarget);

  // Hide target
  toggleTarget.style.setProperty('display', 'none');

  toggleOn.addEventListener('click', () => {
    if (toggleOn.checked) {
      toggleTarget.style.setProperty('display', 'block');

      targetRadios[0].setAttribute('required', 'true');

      for (const el of targetRadios) el.checked = false;
    }
  });

  toggleOff.addEventListener('click', () => {
    if (toggleOff.checked) {
      toggleTarget.style.setProperty('display', 'none');

      targetRadios[0].removeAttribute('required');

      for (const el of targetRadios) el.checked = false;
    }
  });
}

function validateForm(form) {
  const requiredInputs = $$('input[required]', form);
  const minlengthInputs = $$('input[minlength]', form);
  let valid = true;

  for (const input of requiredInputs) {
    if (!input.validity.valid) valid = false;
  }

  for (const input of minlengthInputs) {
    if (input.validity.tooShort) valid = false;
  }

  return valid;
}

function submitForm(form) {
  const formData = new FormData(form);
  formData.append('submit', 'submit');

  // probably too complicated...
  return new Promise(async (resolve, reject) => {
    try {
      const request = await fetch(form.action, {
        method: 'POST',
        body: formData
      });

      let response = await request.text();
      response = JSON.parse(response);

      if (response.success === true) resolve();
      else reject();
    } catch (error) {
      reject();
    }
  });
}

export function initForm(form) {
  const submitButton = $('.js-form-submit-button', form);
  const honeypot = $('.js-form-hnpt input', form);
  const errorEl = $('.js-form-error', form);
  const successEl = $('.js-form-success', form.parentNode);
  let submitInProgress = false;

  // form only works with js enabled to prevent spam,
  // and to make form submit response from other pages easier to handle
  form.setAttribute('action', form.dataset.action);
  submitButton.removeAttribute('disabled');
  honeypot.value = '';

  handleRequiredCheckboxes(form);
  handleRadioToggles(form);

  submitButton.addEventListener('click', async e => {
    // show native warnings for required fields
    if (!validateForm(form)) return;

    e.preventDefault();

    // check required checkboxes fieldset
    if (!validateRequiredCheckboxes(form)) return;

    // prevent multiple submits
    if (submitInProgress) return;
    submitInProgress = true;

    // add button loading class, remove form error class
    submitButton.classList.add('o-button--loading');
    form.parentNode.classList.remove('c-form--error');

    // start submit
    const submitFormPromise = submitForm(form);
    const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));

    try {
      await Promise.all([submitFormPromise, delayPromise]);

      submitInProgress = false;
      submitButton.classList.remove('o-button--loading');
      form.parentNode.classList.add('c-form--success');

      // scroll into view and focus
      const offset =
        $(':scope > *', successEl).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        80;

      if (offset < window.pageYOffset) {
        await scrollTo(offset, 500);
        successEl.setAttribute('role', 'alert');
        setFocus(successEl);
      }
    } catch (error) {
      submitInProgress = false;
      submitButton.classList.remove('o-button--loading');
      form.parentNode.classList.add('c-form--error');

      // focus
      errorEl.setAttribute('role', 'alert');
      setFocus(errorEl);
    }
  });
}

export default () => {
  const forms = $$('.js-form');

  for (const form of forms) {
    initForm(form);
  }
};
