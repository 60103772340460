import loadScript from '../2-helpers/load-script';
import { $, $$ } from '../2-helpers/query-selector';

export default () => {
  const toggles = $$('.js-social-links-toggle');

  for (const toggle of toggles) {
    let listVisible = false;
    // let buttonsLoaded = false;

    toggle.addEventListener('click', () => {
      if (listVisible) {
        toggle.setAttribute('aria-expanded', 'false');
        listVisible = false;
      } else {
        toggle.setAttribute('aria-expanded', 'true');
        listVisible = true;

        // if (!buttonsLoaded) {
        //   const buttons = $$('[data-script]', toggle.parentNode);
        //   if (buttons) {
        //     buttonsLoaded = true;

        //     for (const button of buttons) {
        //       loadScript(button.dataset.script, button.dataset.scriptText);
        //     }
        //   }
        // }
      }
    });
  }
};
