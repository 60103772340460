export default (to, duration = 500) => {
  return new Promise(resolve => {
    // Prevent abrupt scroll stop if bottom of body is reached
    const maxScroll = document.body.offsetHeight - window.innerHeight;
    if (to > maxScroll) to = maxScroll;

    const from = window.pageYOffset;
    const delta = to - from;
    let currentTime = 0;
    const increment = 16.66;

    if (from === to) {
      return resolve();
    }

    // Jump to destination (e.g. for reduced motion)
    if (duration === 0) {
      window.scroll(0, to);
      scrollIntoView;
      return resolve();
    }

    function easeVal(t, b, c, d) {
      const ts = (t /= d) * t;
      const tc = ts * t;
      return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
    }

    function animateScroll() {
      currentTime += increment;
      window.scroll(0, easeVal(currentTime, from, delta, duration));

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        return resolve();
      }
    }

    animateScroll();
  });
};
